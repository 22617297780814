import React from "react"
import { Container, Row, Col, Carousel } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { withPrefix } from "gatsby"

const IndexPage = ({}) => (
  <Layout>
    <SEO title="Início" keywords={[`estacionamento`, `juveve`, `lavacar`]} />
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100 imagem--carrosel"
          src={withPrefix("/images/estacionamentodia.jpg")}
          alt="Logo"
        />
        <Carousel.Caption />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 imagem--carrosel"
          src={withPrefix("/images/camera.jpg")}
          alt="Logo"
        />
        <Carousel.Caption>
          <h3>Sistema de vigilância 24h</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 imagem--carrosel"
          src={withPrefix("/images/portao.jpg")}
          alt="Logo"
        />
        <Carousel.Caption />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 imagem--carrosel"
          src={withPrefix("/images/controle.jpg")}
          alt="Logo"
        />
        <Carousel.Caption>
          <h3>Sistema de intertravamento de portões</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 imagem--carrosel"
          src={withPrefix("/images/volvo.jpg")}
          alt="Logo"
        />

        <Carousel.Caption>
          <h3>Serviço de estética automotiva</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <video width="1400" height="800" controls className="video--entrada">
          <source src={withPrefix("/images/gifentrada.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Carousel.Caption />
      </Carousel.Item>
    </Carousel>

    <section className="sobre" id="sobre">
      <Container>
        <h1 className="titulo--sobre">Sobre</h1>
        <Row>
          <Col md={8}>
            <img
              className="imagem--sobre rounded"
              src={withPrefix("/images/estacionamento.jpg")}
              alt="Logo"
            />
          </Col>
          <Col md={4}>
            <p className="texto--sobre">
              Fundado em 1974, o Estacionamento Juvevê já é um marco da região,
              sendo reconhecido pelo serviço de qualidade e atenção ao cliente.
              Inova ano a ano sempre aumentando o leque de serviços oferecidos
              como convênios, lavacar etc.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    <Container>
      <Row>
        <Col>
          <h1 className="titulo--localizacao">Localização</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!4v1555272518303!6m8!1m7!1s2-aMUTD7zripWE5tRBWw0Q!2m2!1d-25.4097931086968!2d-49.25987339136721!3f258.01886!4f0!5f0.7820865974627469"
            width="100%"
            height="600"
            frameborder="0"
            style={{ border: "0", margin: "auto" }}
            allowfullscreen=""
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
